//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    UserInfo: () => import("./user-info"),
    UserWage: () => import("./user-wage"),
  },
  props: {
    //团队详情
    infoData: {
      type: Object,
      default: null,
    },
    selRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selUser: null,
      loading: false,
      tableData: [],
      userMemberMType: null,
      search: "",
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
    };
  },
  watch: {
    infoData(val, oval) {
      if (val != oval) {
        this.getUserList();
        this.userMemberMType = this.infoData.Teamdata.UserMemberMType;
      }
    },
    search(val, oval) {
      if (val != oval) {
        this.getUserList();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.infoData) {
        this.getUserList();
        this.userMemberMType = this.infoData.Teamdata.UserMemberMType;
      }
    });
  },
  filters: {
    getShape(val) {
      if (val == 1) {
        return "正常";
      } else if (val == 0) {
        return "禁用";
      } else {
        return "删除";
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getUserList();
    },
    getUserList() {
      var data = this.infoData.Membersdata.filter(
        (data) =>
          !this.search ||
          data.Name.toLowerCase().includes(this.search.toLowerCase())
      );
      this.pageData.totalNum = data.length;
      var skip = (this.pageData.pageIndex - 1) * this.pageData.pageSize;
      this.tableData = data.slice(skip, skip + this.pageData.pageSize);
    },
    imgChange,
    /**
     * 成员设置时薪
     */
    handleHourlyWage(val) {
      this.selUser = val;
      this.$modal.show("userWageM");
    },
    /**
     * 删除成员
     */
    handleDel(row) {
      this.$confirm("此操作将删除该成员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            ids: [row.Id],
            teamId: this.selRow.Id,
          };
          this.$http.post("/Teams/DelMembers.ashx", data).then((resp) => {
            if (resp.res == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.$emit("loadData");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // /**
    //  * 获取团队成员列表
    //  */
    // getTeamList() {
    //   this.loading = true;
    //   this.$http
    //     .get("/Teams/TeamManagementDetail.ashx", {
    //       params: { teamId: this.selRow.Id },
    //     })
    //     .then((resp) => {
    //       if (resp.res == 0) {
    //         this.tableData = resp.data.Membersdata;
    //         this.userMemberMType = resp.data.Teamdata.UserMemberMType;
    //       }
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    /**
     * 查看成员详情
     */
    handleUserDetail(val) {
      this.selUser = val;
      this.$modal.show("userInfo");
    },
  },
};
